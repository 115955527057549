<template>
  <el-main>
    <el-row type="flex" justify="space-between">
      <el-col :span="12">
        <BreadCrumb />
      </el-col>
      <el-col :span="7">
        <el-button @click="$router.push('/work/users')"><i class="el-icon-back"></i> Back</el-button>
        <el-button type="success" @click="submitForm('form')"><i class="el-icon-check" /> Save</el-button>
      </el-col>
    </el-row>
     <el-divider></el-divider>
    <el-row style="margin-top: 10px">
      <el-form class="user-form" :model="form" :rules="rules" ref="form" label-width="200px" label-suffix=":">
        <el-form-item label="Email" prop="email" required>
          <el-input :disabled="this.$route.params.id !== 'new'" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="First Name" prop="firstName" required>
          <el-input v-model="form.firstName"></el-input>
        </el-form-item>
        <el-form-item label="Last name" prop="lastName" required>
          <el-input v-model="form.lastName"></el-input>
        </el-form-item>
        <div v-if="this.$route.params.id === 'new' || isResetPassword">
          <el-form-item label="Password" prop="password" required>
            <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Confirm password" prop="checkPass">
            <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="Password">
            <div><el-button type="success" @click="changeReset"><i class="el-icon-wallet" />Reset</el-button></div>
          </el-form-item></div>
      </el-form>
    </el-row>
  </el-main>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import md5 from 'js-md5'
export default {
  components: { BreadCrumb },
  data () {
    const query = this.$route.query
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input password'))
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input password'))
      } else if (value !== this.form.password) {
        callback(new Error('The two passwords are inconsistent'))
      } else {
        callback()
      }
    }
    return {
      isResetPassword: this.$route.params.id === 'new',
      form: {
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        checkPass: '',
      },
      rules: {
        email: [
          { required: true, trigger: 'blur' },
          { type: 'email', message: 'Please input the correct email address', trigger: ['blur', 'change'] }
        ],
        firstName: [
          { required: true, trigger: 'blur' }
        ],
        lastName: [
          { required: true, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      loading: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: async function () {
    this.getObject()
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    changeReset () {
      this.isResetPassword = true
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form._id = this.$route.params.id
          let action = this.form._id === 'new' ? 'addUser': 'updateUser'
          let doc =  {
            email: this.form.email,
            firstName: this.form.firstName,
            lastName: this.form.lastName
          }
          if (action === 'updateUser') {
            doc._id = this.form._id
          }
          if (this.isResetPassword) {
            doc.password = md5(this.form.password)
          }
          this.$store.httpDispatch({})(action, doc).then((res) => {
            this.$notify.success({ title: 'Successful operation', duration: 5000 })
            this.$router.push('/work/users')
          }).catch((err) => {
            console.log(err)
            this.$notify.error({ title: 'Error', message: `[${err.data.code}] ${err.data.msg}`, duration: 5000 })
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getDate (createdAt) {
      return moment(createdAt).format('YYYY-MM-DD')
    },
    async getObject () {
      this.loading = true
      let result = {}
      if (this.$route.params.id === 'new') {
        return
      }
      try {
        result = await this.$store.httpDispatch({})('getUserObj', {
          _id: this.$route.params.id
        })
      } catch (err) {
        console.log(err)
        this.$notify({
          title: 'Request failed',
          type: 'error'
        })
        this.loading = false
        return false
      }
      const { code, data } = result.data
      console.log(result)
      if (code === 0) {
        const user = data.user
        this.form = {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName
        }
      }
      this.loading = false
    }
  }
}
</script>
<style lang="less">
.user-form {
  .el-form-item__label {
    text-align: left;
  }
  .el-form-item__content {
    text-align: left
  }
  .el-input {
    width: 250px;
  }
  .el-textarea {
    width: 500px;
  }
  .user-upload-input {
    float: left;
  }
}
</style>
